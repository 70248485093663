import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://6a61ec724457455f90fd6651945781ac@sentry.io/1441235',
  environment: window.SENTRY_ENV,
  release: window.SENTRY_RELEASE,
});

const scope = Sentry.getCurrentScope();
scope.setUser({
  username: window.SENTRY_USERNAME,
  segment: window.SENTRY_USER_SEGMENT,
});
